<template>
  <div class="p-10 pr-16 all">
    <success v-if="activeSuccess" :message="message" :activation="activeSuccess"  @oga="activeSuccess = false"/>
    <recap :activation="activeRecap" v-if="activeRecap" @oga="retourRecap"/>
    <div>
      <div
        class="flex items-center"
      >
        <div class="w-4/5 flex text-left text-c36 items-center font-c6">
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            original
            @click="retour"
          />
          <div class="ml-4 w-3/5">
            Virement {{ virementType }}
          </div>
        </div>
      </div>

      <div class="mt-10 pl-4">
        <div class="flex mt-6 justify-center bg-white rounded-10 p-10 text-c20">
          Virement {{ virementType }}
        </div>
      </div>

      <div class="mt-10 pl-4">
        <virement/>
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import virement from '../../component/cdevs/virement/template'
import recap from '../../component/popup/recapTransfere'
import success from '../../component/popup/success'

export default {
  name: "Index",

  components: {
    virement,
    recap,
    success
  },

  data () {
    return {
      icons: {
        back,
      },
      position: 1,
      activeRecap: false,
      activeSuccess: false,
      message: 'Transfert éffectué avec succes',
      virementType: 'Interne'
    }
  },

  created () {
    this.virementType = this.$store.getters.trackVirement
    console.log(this.virementType)
  },

  methods: {
    selected (index) {
      this.position = index
    },

    continuer () {
      if (this.position < 3) {
        this.position = this.position + 1
      } else {
        this.activeRecap = true
      }
    },

    retourRecap (answer) {
      if (answer) {
        this.activeSuccess = true
      }
      this.activeRecap = false
    },

    retour () {
      window.history.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: transparent;
}
.all{
  min-height: 100vh;
}

</style>
