<template>
  <success :activation="ativeSuccess" :pdf="pdf" :message="message" v-if="ativeSuccess" @oga="retourSuccess"/>
  <div class="bg-white rounded-10 p-10 pt-20 pb-32 text-left flex justify-center">
    <div class="w-1/2">
      <div v-if="optionCompte.length > 0">
        <div class="text-c12">
          Compte bancaire de depart
        </div>
        <multiselect
            v-if="virementType === 'Interne'"
            background="#fff"
            :value-t="bankDefault"
            border="1px solid #DCDCE4"
            class="mt-2"
            :option="optionCompte"
            @info="retourDepart"
        />

        <inputo class="mt-2" v-if="virementType !== 'Interne'" @info="retourDepart"/>

        <div class="text-c12 mt-6">
          Compte bancaire de destination
        </div>
        <multiselect
            background="#fff"
            :value-t="bankDefaultDest"
            border="1px solid #DCDCE4"
            class="mt-2"
            :option="optionCompte"
            @info="retourReception"
        />
      </div>

      <div class="text-c12 mt-6">
        Montant
      </div>
      <inputo class="mt-2" type="number" @info="retourAmount"/>

      <div class="text-c12 mt-6">
        Date du virement
      </div>

      <datepicker placeholder="Cliquez ici" class="w-full mt-2" mode="dateTime" @info="retourDate"/>

      <upload value="Pièce justificative" class="mt-10" @info="retourUpload"/>

      <div class="mt-10 text-center w-full text-red" v-if="error">{{ error }}</div>

      <div class="mt-10">
        <bouton label="Enregistrer" :charge="charge" @info="save"/>
      </div>
    </div>

  </div>
</template>

<script>
import inputo from '../../helper/form/input'
import multiselect from '../../helper/form/multiselect'
import datepicker from '../../helper/form/datePicker'
import upload from '../../helper/form/uploadPreuve'
import bouton from '../../helper/add/button'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'
import success from '../../popup/success'

export default {
  name: "Index",
  components: {
    inputo,
    multiselect,
    datepicker,
    upload,
    bouton,
    success
  },
  props: {
  },

  data () {
    return {
      optionType: ['Chèque', 'Espèce'],
      optionCompte: [],
      response: {},
      allData: [],
      ativeSuccess: false,
      message: 'Virement enregistré avec succès',
      charge: false,
      error: null,
      pdf: null,
      wallet: null,
      bankDefault: 'Selectionner',
      bankDefaultDest: 'Selectionner',
      virementType: 'Interne'
    }
  },

  created () {
    this.virementType = this.$store.getters.trackVirement
    this.wallet = this.$store.getters.trackTemporal
    this.allBanque()
  },

  methods: {
    retourDepart (answer) {
      if (answer !== 'Selectionner' && this.virementType === 'Interne') {
        this.response.depart = answer
        for (let item in this.allData) {
          if (this.allData[item].bankName === answer) {
            this.response.departBank = this.allData[item].id
          }
        }
      } else {
        this.response.departBank = answer
      }
    },

    retourReception (answer) {
      if (answer !== 'Selectionner') {
        this.response.reception = answer
        for (let item in this.allData) {
          if (this.allData[item].bankName === answer) {
            this.response.receptionBank = this.allData[item].id
          }
        }
      }
    },

    retourUpload (answer) {
      this.response.file = answer
    },

    retourSuccess (answer) {
      this.ativeSuccess = answer
      this.$router.push('/banques')
    },

    allBanque () {
      http.get(apiroutes.baseURL + apiroutes.allBank)
          .then(response => {
            console.log(response)
            this.allData = response.banks
            const data = response.banks
            let tab = []
            for (let item in data) {
              tab.push(data[item].bankName)
              if (data[item].bankName === this.wallet.bankName) {
                this.bankDefault = data[item].bankName
                if (this.virementType !== 'Interne') {
                  this.bankDefaultDest = data[item].bankName
                }
              }
            }
            this.optionCompte = tab
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    retourDate (answer) {
      this.response.date = answer
    },

    retourAmount (answer) {
      this.response.amount = parseInt(answer)
    },

    save() {
      if (this.response.file) {
        this.saveFile(this.response.file)
      } else {
       this.error = 'Veuillez ajouter une preuve pour continuer'
      }
    },

    //Ici j'enregistre les fichiers et je récupère des url
    saveFile (file) {
      this.charge = true
      let data = new FormData()
      data.append('files', file)
      let config = {
        header : {
          'Content-Type' : 'multipart/form-data'
        }
      }

      http.post(apiroutes.baseURL + apiroutes.upload, data, config)
          .then(response => {
            if (this.virementType === 'Interne') {
              this.finaly(response[0].url)
            } else {
              this.finalyEntrant(response[0].url)
            }
          })
          .catch(error => {
            this.error = 'Le fichier trop volumineux.'
            console.log(error)
          })
    },

    finaly (url) {
      const obj = {
        receive_bank: this.response.receptionBank,
        send_bank: this.response.departBank,
        amount: this.response.amount,
        date: this.response.date,
        proof: url,
        meta: {
          file: url
        }
      }

      http.post(apiroutes.baseURL + apiroutes.virement, obj)
          .then(response => {
            console.log(response)
            this.pdf = response.pdf
            this.ativeSuccess = true
          })
          .catch(error => {
            if (error.status === 409) {
              this.error = 'Solde insuffisant'
            } else {
              this.error = 'Une erreur s\'est produite'
            }
            console.log(error)
          })
    },

    finalyEntrant (url) {
      const obj = {
        receive_bank: this.response.receptionBank,
        sender_name: this.response.departBank,
        amount: this.response.amount,
        date: this.response.date,
        proof: url,
        meta: {
          file: url
        }
      }

      http.post(apiroutes.baseURL + apiroutes.virementEntrant, obj)
          .then(response => {
            console.log(response)
            this.pdf = response.pdf
            this.ativeSuccess = true
          })
          .catch(error => {
            if (error.status === 409) {
              this.error = 'Solde insuffisant'
            } else {
              this.error = 'Une erreur s\'est produite'
            }
            console.log(error)
          })
    },
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.bloc2 {
  border-left: 0.352891px solid #DDDDDD;
}
.inputo {
  background: #F5F5F5;
  border: 0.956973px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 4.78486px;
  height: 36.36px;
}
</style>
